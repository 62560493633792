$padding: calc(min(10vh, 15vw));

.appContainer {
  position: relative;
  z-index: 1;
  margin: 0 auto;
  max-width: 900px;
  display: flex;
  align-content: center;
  min-height: 80vh;
  flex-wrap: wrap;
}

.pageWrapper {
  display: flex;
}

.pageContainer {
  padding: $padding;
}

.header {
  width: 100%;
  padding: $padding $padding 0;
}

@media screen and (orientation: landscape) {
  .appContainer {
    min-height: 80vh;
    //display: flex;
  }
  .pageWrapper {
    align-items: center;
  }
  .pageContainer {
    align-items: center;
  }
}