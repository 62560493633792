.header {
  display: flex;
  box-sizing: border-box;
  justify-content: left;
  align-items: center;

  h2 {
    font-size: 2rem;
  }
  h3 {
    font-size: 1rem;
  }
  h2, h3 {
    margin: 0rem 0;
    font-weight: 300;
  }
}

@media screen and (orientation: landscape) {
  .header {
    min-width: 250px;
    max-width: 50vw;
    padding-right: 0;
  }
}

@media screen and (orientation: portrait), (max-width: 800px) {
  .header {
    padding-bottom: 0;
  }
}

.content {
  overflow: hidden;
}
