@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono&family=Roboto:wght@100;300;400;900&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto+Mono&family=Roboto:wght@100;300;400;900&display=swap);
body {
  margin: 0;
  font-family: 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1 {
  font-weight: 300;
}
html,body{background:#0b1620;color:#bed6ea;font-size:calc(10px + 0.6vh);width:100%;height:100%;overflow:hidden}body{margin:0;font-family:'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale}code{font-family:source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace}h1,h2,h3,h4,h5,h6{color:#fff;font-weight:300}p{line-height:1.5em}a{color:#fff}*:focus{outline:none}

.App_appContainer__1hNk1{position:relative;z-index:1;margin:0 auto;max-width:900px;display:flex;align-content:center;min-height:80vh;flex-wrap:wrap}.App_pageWrapper__1Jej4{display:flex}.App_pageContainer__IMjRo{padding:calc(min(10vh, 15vw))}.App_header__2L3DO{width:100%;padding:calc(min(10vh, 15vw)) calc(min(10vh, 15vw)) 0}@media screen and (orientation: landscape){.App_appContainer__1hNk1{min-height:80vh}.App_pageWrapper__1Jej4{align-items:center}.App_pageContainer__IMjRo{align-items:center}}

.Background_background__1LRx_{width:100%;height:100%;overflow:hidden;position:fixed;z-index:0;background:#0b1620}

.styles_button__12U2K,.styles_lg__2qigu,.styles_sm__3wLv_{background-size:100% 210%;background-repeat:no-repeat;position:relative;background-image:linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.5) 50%, #bed6ea 50%, #bed6ea 100%);background-position-y:100%;display:inline-block;padding:0.25em 0.7em;margin:0.5em;color:#bed6ea;border:none;font-size:1rem;transition:ease-in-out background-position 0.2s;cursor:pointer;text-decoration:none;line-height:2em;font-weight:400}.styles_button__12U2K:focus,.styles_lg__2qigu:focus,.styles_sm__3wLv_:focus{border:solid 2px #fff;margin:calc(0.5em - 2px);color:#fff}.styles_button__12U2K:hover,.styles_lg__2qigu:hover,.styles_sm__3wLv_:hover{background-position-y:0;color:#1d3e59}.styles_lg__2qigu{font-size:1.25rem}.styles_sm__3wLv_{font-size:0.8rem}

.styles_cursor__2SNdl{display:inline-block;width:0.1em;height:1em;background:#fff;-webkit-animation:styles_blink__3iP6b 1s infinite;animation:styles_blink__3iP6b 1s infinite;alignment:bottom}@-webkit-keyframes styles_blink__3iP6b{from,to{opacity:1}50%{opacity:0}}@keyframes styles_blink__3iP6b{from,to{opacity:1}50%{opacity:0}}

.styles_profilePic__Ets3N{width:70px;height:70px;overflow:hidden;border-radius:100px;margin-right:2vh;flex:0 0 70px}.styles_profilePic__Ets3N>img{max-width:100%}

.styles_header__110Ax{display:flex;box-sizing:border-box;justify-content:left;align-items:center}.styles_header__110Ax h2{font-size:2rem}.styles_header__110Ax h3{font-size:1rem}.styles_header__110Ax h2,.styles_header__110Ax h3{margin:0rem 0;font-weight:300}@media screen and (orientation: landscape){.styles_header__110Ax{min-width:250px;max-width:50vw;padding-right:0}}@media screen and (orientation: portrait), (max-width: 800px){.styles_header__110Ax{padding-bottom:0}}.styles_content__3NbzV{overflow:hidden}

.flipDown_appear__3-bZ5{transform:perspective(500px) rotateX(90deg);transform-origin:0 0 0}.flipDown_appearActive__I347n{transform:perspective(500px) rotateX(0);transition:transform 0.5s 2s}

.animateName_appear__3fGj1{transform:translateX(-100%)}.animateName_appearActive__3Os5H{transform:translateX(0);transition:transform ease 1s 1s}

.animateProfilepic_appear__3XfLg{opacity:0;transform:translateX(100%) rotateZ(90deg)}.animateProfilepic_appearActive__1Ghh6{opacity:1;transform:translateX(0) rotateZ(0deg);transition:opacity 1s, transform ease 1s 0.5s}

